.progress-0>.MuiLinearProgress-bar1Determinate {
    background-color: purple !important;
}

.progress-1>.MuiLinearProgress-bar1Determinate {
    background-color: yellow !important;
}

.progress-2>.MuiLinearProgress-bar1Determinate {
    background-color: rgb(182, 82, 98) !important;
}

.progress-3>.MuiLinearProgress-bar1Determinate {
    background-color: green !important;
}

.action_description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.MuiPaper-elevation8 {
    box-shadow: none !important;
}

.reportmenu .MuiMenu-paper {
    width: 99px;
    height: 36px;   
    border-radius: 8px;
    border: 1px solid #DCDCDC;
    margin-top: 26px;
    overflow-y: hidden;
    margin-left: 28px;
}

.MuiIconButton-root:hover {
    background-color: #F8F8F8 !important;
}

.MuiAvatar-root.error {
    margin-right: 10px;
}

.MuiAvatar-root.success {
    margin-right: 10px;
}